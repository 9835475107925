body {
    margin: 0;
    padding: 0;
    height: auto;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.tableCollapseStyle {
    white-space: normal;
    background-color: rgba(0, 0, 0, 0.04);
}

.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
    height: initial;
}

.content-head {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
}

.content-body {
    margin-top: 180px;
}

.close-icon-modal {
    display: inline-block;
    position: absolute;
    right: -6px;
    top: -6px;
    background: #cd201f;
    height: 22px;
    width: 22px;
    text-align: center;
    cursor: pointer;
    color: #fff;
}